import { useTranslation } from "react-i18next";
import { useEditorDataContext } from "../../context/EditorDataProvider";
import styled from "styled-components";

const StyledWrap = styled.div`
    display: flex;
    gap: var(--medium-gap);
`;

const SizePage = ({ ...props }) => {
    const { getCurrentPageData } = useEditorDataContext();
    const page = getCurrentPageData();
    const { width, height, bleed = 0 } = page;
    const { t } = useTranslation();

    return (
        <StyledWrap {...props}>
            <div>
                <span>
                    {t("width")} <strong>{width - bleed} CM</strong>{" "}
                    {t("height")} <strong>{height - bleed} CM</strong>
                </span>
            </div>
            <div>
                <span>
                    {t("bleed")} <strong>{bleed} CM</strong>
                </span>
            </div>
        </StyledWrap>
    );
};

export default SizePage;
