//mobile: 768,
export const breakpoints = {
    mobile: 1024,
    // tablet: 1024,
};

export const device = {
    mobile: `max-width: ${breakpoints.mobile}px`,
    // tablet: `max-width: ${breakpoints.tablet}px`,
    desktop: `min-width: ${breakpoints.mobile}px`,
};
