import { useAppSelector } from "../../redux/hook";
import pix from "../../brand-config/images/pix-logo.svg";
import stickit from "../../brand-config/images/stick-it-logo.svg";
import beeriEcommerce from "../../brand-config/images/beeri-ecommerce.png";
import styled from "styled-components";

const StyledImage = styled.img<{ isBeeriEcommerce: boolean }>`
    max-width: ${(props) => (props.isBeeriEcommerce ? "120px" : "55px")};
`;

const logoMap = {
    pix: pix,
    beeriEcommerce: beeriEcommerce,
    default: stickit,
};

const Logo = ({ ...props }) => {
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { logo } = brandConfig;

    const getLogo = (logo: string): string => {
        return logoMap[logo as LogoKey] || logoMap.default;
    };

    type LogoKey = keyof typeof logoMap;

    const imageLogo = getLogo(logo);
    const isBeeriEcommerce = logo === "beeriEcommerce";

    return (
        <StyledImage
            {...props}
            src={imageLogo}
            alt="לוגו"
            isBeeriEcommerce={isBeeriEcommerce}
        />
    );
};

export default Logo;
