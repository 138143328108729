export const allowedOrigins = [
    "http://192.168.0.22:8082/",
    "https://pix.co.il/",
    "https://local-pix.co.il/",
    "https://sandbox.pix.co.il/",
    "https://sandbox.stickit.co.il/",
    "https://stickit.co.il/",
    "https://new.pix.co.il/",
    "https://sandbox.new.pix.co.il/",
    "https://stickit.dev:8031/",
    "https://beeri-ecommerce.co.il/",
    "https://beeri-ecommerce.dev/",
    "https://sandbox.beeri-ecommerce.co.il/",
];
