import { useTranslation } from "react-i18next";
import { usePageContext } from "../../context/PageProvider";
import { useEditorDataContext } from "../../context/EditorDataProvider";

const PageIndicator = ({ ...props }) => {
    const { page: pageIndex } = usePageContext();
    const { editorData } = useEditorDataContext();
    const { t } = useTranslation();
    const pages = editorData.pages.length;
    if (pages === 1) return null;
    return (
        <div {...props}>
            {t("PageNumber", { current: pageIndex + 1, total: pages })}
        </div>
    );
};

export default PageIndicator;
