import React, { useCallback, useEffect, useRef, useState } from "react";
import { MainButton, ResetButton } from "./buttons/buttons";
import { useEditorDataContext } from "../context/EditorDataProvider";
import { getTargetOrigin } from "../utils/origin";
import { ReactComponent as SaveIconSvg } from "../assets/icons/save.svg";
import { ReactComponent as CloudIcon } from "../assets/icons/cloud.svg";
import styled from "styled-components";
import { useAppSelector } from "../redux/hook";
import ReactLoading from "react-loading";
import { EPostMessageActions } from "../enums/post-message-actions.enum";
import { useTranslation } from "react-i18next";
import usePostMessageListener from "../hooks/usePostMessageListener";
import { useQueryString } from "../context/QueryStringProvider";

enum ESaveProjectTypes {
    SAVE = "save",
    SAVE_AS = "saveAs",
    EMPTY = "empty",
}

enum ELoadingMessage {
    SAVED = "loading:saved",
    START = "loading:start",
    END = "loading:end",
}

const StyledWrap = styled.div`
    display: flex;
    align-items: center;
    gap: var(--small-gap);
`;

const StyledCloud = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
        font-size: var(--small-font-size);
        margin-top: -5px;
        text-align: center;
    }
`;

interface ISaveProject {
    label?: boolean;
}

const StyledButton = styled(ResetButton)`
    padding: 0;
    display: flex;
    align-items: center;
    gap: var(--medium-gap);
`;
const SaveProject = ({ label = false }: ISaveProject) => {
    const { t } = useTranslation();
    const { editorData, getEditorDataOutPut } = useEditorDataContext();
    const [loadingMessage, setLoadingMessage] = useState<ELoadingMessage>(
        ELoadingMessage.END,
    );
    const origin = getTargetOrigin();
    const ref: React.RefObject<any> = useRef();
    const { brandConfig } = useAppSelector((state) => state.brandConfig);
    const { primaryColor } = brandConfig;
    const { isGraphicDesigner } = useQueryString();

    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "s" && (e.ctrlKey || e.metaKey) && !e.shiftKey) {
                e.preventDefault();
                handleOnSaveProject(null, ESaveProjectTypes.SAVE);
            } else if (
                e.key === "s" &&
                e.shiftKey &&
                (e.ctrlKey || e.metaKey)
            ) {
                e.preventDefault();
                handleOnSaveProject(null, ESaveProjectTypes.SAVE_AS);
            }
        },
        [editorData],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleMessage = (data: ELoadingMessage) => {
        if (Object.values(ELoadingMessage).includes(data)) {
            setLoadingMessage(data);

            if (data === ELoadingMessage.SAVED) {
                setTimeout(() => setLoadingMessage(ELoadingMessage.END), 3000);
            }
        }
    };

    usePostMessageListener(handleMessage);

    function handleOnSaveProject(
        e: React.MouseEvent | null,
        type: ESaveProjectTypes,
    ) {
        if (origin) {
            window.parent.postMessage(
                {
                    action: EPostMessageActions.SAVE_PROJECT,
                    response: getEditorDataOutPut(),
                    type,
                },
                origin,
            );
        }
    }

    return (
        <StyledWrap>
            {loadingMessage === ELoadingMessage.SAVED && (
                <StyledCloud>
                    <CloudIcon className="toolbar-icon" />
                    <div className="text">{t("changedSaved")}</div>
                </StyledCloud>
            )}
            {loadingMessage === ELoadingMessage.START && (
                <ReactLoading
                    type="bars"
                    color={primaryColor}
                    height={35}
                    width={35}
                />
            )}

            {isGraphicDesigner ?
                <MainButton
                    onClick={(event) =>
                        handleOnSaveProject(event, ESaveProjectTypes.EMPTY)
                    }
                >
                    <span>{t("saveAndFinish")}</span>
                </MainButton>
            :   <StyledButton
                    className="save"
                    onClick={(event) =>
                        handleOnSaveProject(event, ESaveProjectTypes.EMPTY)
                    }
                    ref={ref}
                >
                    <SaveIconSvg className="toolbar-icon" />
                    {label && <span>{t("save")}</span>}
                </StyledButton>
            }
        </StyledWrap>
    );
};

export default SaveProject;
