import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import "draft-js/dist/Draft.css";
import {
    EUpdateEditorActions,
    useEditorDataContext,
} from "../../context/EditorDataProvider";
import styled from "styled-components";
import { HistoryContext } from "../../context/HistoryProvider";
import Resizable from "./utils/Resizable";
import { IEditorDataPageItemText } from "../../interfaces/editor-data.interface";

import {
    ContentState,
    Editor,
    EditorState,
    getDefaultKeyBinding,
} from "draft-js";
import { getOppositeScale } from "../../utils/utils";
import useIsMobile from "../../hooks/useIsMobile";
import { useQueryString } from "../../context/QueryStringProvider";
import { EEditorDataItemTypes } from "../../enums/editor-data-item-type.enum";

function getLineBreaks(node: any) {
    if (!node || !node.parentNode || node.nodeType !== 3) return [];
    const range = document.createRange();
    const lines = [];
    range.setStart(node, 0);
    let prevBottom = range.getBoundingClientRect().bottom;
    let current = 1;
    let lastFound = 0;
    let bottom = 0;
    const str = node.textContent;

    while (current <= str.length) {
        range.setStart(node, current);
        if (current < str.length - 1) range.setEnd(node, current + 1);
        bottom = range.getBoundingClientRect().bottom;
        if (bottom > prevBottom) {
            lines.push(str.substr(lastFound, current - lastFound));
            prevBottom = bottom;
            lastFound = current;
        }
        current++;
    }

    lines.push(str.substr(lastFound));
    return lines;
}

let isRepeat = false;
interface ITextBox {
    currentItem: IEditorDataPageItemText;
}
const TextBox = ({ currentItem }: ITextBox) => {
    const { editorData, updateEditorData, getCurrentPageData } =
        useEditorDataContext();
    const page = getCurrentPageData();
    const items = page.items;
    const printColor = page.printColor ? page.printColor : null;
    const { saveHistory, numStateRedo }: any = useContext(HistoryContext);
    const focusedItem = items.find((item) => item.focus);
    const oppositeScale = getOppositeScale(page.scale);
    const isMobile = useIsMobile();
    const { isGraphicDesigner }: any = useQueryString();

    const focusRef = useRef(null);
    const wrapperRef: React.RefObject<any> = useRef(null);
    const isCustomerField = isGraphicDesigner && !!currentItem?.customerField;
    const [editorState, setEditorState] = useState(() => {
        const state = EditorState.createWithContent(
            ContentState.createFromText(currentItem.content),
        );

        return EditorState.moveFocusToEnd(state);
    });

    const getTextBoxHeight = () => {
        if (!wrapperRef.current) {
            return 0;
        }
        const draftRoot = wrapperRef.current.querySelector(
            ".public-DraftEditor-content",
        );
        const draftContentHeight = draftRoot.querySelector(
            `[data-contents="true"]`,
        );
        return draftContentHeight.clientHeight / oppositeScale;
    };

    function getLinesLines() {
        let strArray: any = [];
        if (!wrapperRef.current) {
            return strArray;
        }
        const dataText =
            wrapperRef.current.querySelectorAll('[data-text="true"]');
        if (dataText.length > 0) {
            for (let i = 0; i < dataText.length; i++) {
                if (currentItem.rotation && currentItem.rotation !== 0) {
                    //Has Rotation (if it has rotation, the server makes the auto lines).
                    strArray.push(dataText[i].textContent);
                } else {
                    ////Not Rotation.
                    const strWithLineBreaks = getLineBreaks(
                        dataText[i].lastChild,
                    );
                    if (strWithLineBreaks.length > 1) {
                        strArray = strArray.concat(strWithLineBreaks);
                    } else {
                        strArray.push(
                            strWithLineBreaks.length ?
                                strWithLineBreaks[0]
                            :   "",
                        );
                    }
                }
            }
        }
        return strArray.join("\n");
    }

    async function dynamicResize() {
        const textBoxHeight = getTextBoxHeight() * oppositeScale;
        if (textBoxHeight && textBoxHeight !== currentItem.height) {
            const newProps = {
                ...currentItem,
                height: textBoxHeight,
                content: getLinesLines(),
            };
            updateEditorData(newProps, EUpdateEditorActions.UPDATE_ITEM);
        }
    }

    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (!focusedItem) {
                return false;
            }
            if (focusedItem.type !== EEditorDataItemTypes.TEXT) {
                return false;
            }

            const draftEditor: HTMLElement | null = document.querySelector(
                ".item-focused .public-DraftEditor-content",
            );
            if (!draftEditor) {
                return false;
            }
            //ESC
            if (e.key === "Escape") {
                e.preventDefault();
                draftEditor.blur();
                updateEditorData(
                    { ...focusedItem, firstClick: false },
                    EUpdateEditorActions.UPDATE_ITEM,
                );
            }
            //Enter
            else if (e.key === "Enter") {
                e.preventDefault();
                draftEditor.focus();
                const getSelection: Selection | null = window.getSelection();
                if (!getSelection) {
                    return false;
                }
                getSelection.selectAllChildren(draftEditor);
                getSelection.collapseToEnd();
                updateEditorData(
                    { ...focusedItem, firstClick: true },
                    EUpdateEditorActions.UPDATE_ITEM,
                );
            }
        },
        [editorData],
    );

    useEffect(() => {
        dynamicResize();
    }, [editorData, currentItem.fontSize]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        if (numStateRedo) {
            const state = EditorState.createWithContent(
                ContentState.createFromText(currentItem.content),
            );
            setEditorState(EditorState.moveFocusToEnd(state));
            dynamicResize();
        }
    }, [numStateRedo]);

    useEffect(() => {
        const state = EditorState.createWithContent(
            ContentState.createFromText(currentItem.content),
        );
        setEditorState(EditorState.moveFocusToEnd(state));
    }, [currentItem?.customerField]);

    useEffect(() => {
        const content = getLinesLines();
        updateEditorData(
            { ...currentItem, content },
            EUpdateEditorActions.UPDATE_ITEM,
        );
    }, [editorState]);

    const onChangeEditorState = async (newEditorState: any) => {
        const currentPlainText = editorState.getCurrentContent().getPlainText();
        const newPlainText = newEditorState.getCurrentContent().getPlainText();
        const emojiRegex = /\p{Emoji_Presentation}/u;

        if (emojiRegex.test(newPlainText)) {
            const edState: any = EditorState;
            newEditorState = edState.createWithText(
                newPlainText.replace(/\p{Emoji_Presentation}/gu, ""),
            );
        }
        setEditorState(newEditorState);
        if (currentPlainText !== newPlainText && !isRepeat) {
            saveHistory(
                await updateEditorData(
                    currentItem,
                    EUpdateEditorActions.UPDATE_ITEM,
                ),
            );
        }
    };

    const inside =
        currentItem.templateCss ? JSON.parse(currentItem.templateCss) : {};
    const contentStyle = {
        ...inside,
        fontWeight: currentItem.fontWeight,
    };

    if (currentItem.font !== "") {
        contentStyle.fontFamily = currentItem?.font;
    }

    if (currentItem.underline) {
        contentStyle.textDecoration = "underline";
    }

    if (currentItem.italic) {
        contentStyle.fontStyle = "italic";
    }

    if (currentItem.fontSize) {
        contentStyle.fontSize = `${currentItem.fontSize}pt`;
        contentStyle.lineHeight = `${currentItem.fontSize / 0.75}px`;
    }

    if (printColor) {
        contentStyle.color = printColor;
    } else if (currentItem.color !== "") {
        contentStyle.color = currentItem.color;
    }

    function myKeyBindingFn(e: React.KeyboardEvent): any {
        isRepeat = e.repeat;
        if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.which === 90) {
            return false;
        }
        if ((e.ctrlKey || e.metaKey) && e.which === 90 && !e.shiftKey) {
            return false;
        }

        return getDefaultKeyBinding(e);
    }

    const direction: any = currentItem?.direction;
    const editable = isMobile && !currentItem?.editable;

    return (
        <Resizable
            currentItem={currentItem}
            rotate={true}
            zoomable="w,e,nw, ne, se, sw"
            excludeAspectRatioPoints={true}
        >
            <WrapEditorStyled
                ref={wrapperRef}
                style={contentStyle}
                color={contentStyle.color}
            >
                <Editor
                    readOnly={currentItem.lock || editable || isCustomerField}
                    stripPastedStyles={true}
                    ref={focusRef}
                    textDirectionality={direction}
                    textAlignment={
                        currentItem.align ? currentItem?.align : "center"
                    }
                    editorState={editorState}
                    placeholder={
                        currentItem.placeHolderText ?
                            currentItem.placeHolderText
                        :   ""
                    }
                    keyBindingFn={myKeyBindingFn}
                    onChange={onChangeEditorState}
                />
            </WrapEditorStyled>
        </Resizable>
    );
};

export default TextBox;

const WrapEditorStyled = styled.span`
    .public-DraftEditorPlaceholder-inner {
        opacity: 0.4;
        color: ${({ color }) => (color ? color : "black")};
    }
`;
